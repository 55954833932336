:root {
  --primary-heading-font: "SF Pro Display-Bold";
  --primary-sub-heading-font: "SF Pro Display-Regular";
  --primary-text-color: #efefef;
  --secondary-text-color: #cfcfcf;
  --tertiary-text-color: #ffffff;
  --quaternary-text-color: #e3e3e3;
  --primary-header-color: #e8e8e8;
  --body-dark: #212121;
  --primary-CTA: #000000;
  --secondary-CTA: #f2f2f2;
  --disabled-CTA: #a5a5a5;
  --selected-tab: #16a9b1;
  --card-background: #383837;
}

.cta-button-main {
  background: transparent linear-gradient(117deg, #16a9b1 0%, #b5d654 100%) 0%
    0% no-repeat padding-box;
  width: 100%;
  height: 48px;
  position: relative;
  border-radius: 8px;
  opacity: 1;
}

.cta-button-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  height: 90%;
  position: relative;
  border-radius: 8px;
  opacity: 1;

  text-align: left;
  font-family: var(--primary-heading-font);
  font-size: 1rem;
  letter-spacing: 0px;
  color: var(--primary-CTA);
  text-transform: uppercase;
}

.modal-line-space {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-line {
  width: 52px;
  height: 0px;
  border: 4px solid #c5c5c5;
  opacity: 1;
  border-radius: 24px;
}
