#home{
    background: #F7FEFF;
    background-image: none;
}

.progress{
    margin: 0 -15px;
    background-color: white;
    height: 5px;
    width: auto;
}
.mci-container{
    display: flex;
    /* width: 37%; */
    align-items: center;
    justify-content: center;
    height: auto;
}

.progress-bar{
    /* width: 60%; */
    background-color: #BBCEFF;
}

.mountain-image-container{
    display: flex;
    justify-content: center;
    margin: 0 -15px;
}

.mountain-image{
    width: 100%;
}

.questions-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 0.5rem 0 0.5rem;
}

.mci-questions-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 2rem;
    width: 25%;
}

.mci-questions-wrapper {
    margin: 0;
}

.mci-questions-listing{
    grid-gap: 1.5rem;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;

}

.mci-questions-btn{
    min-width: 100%;
    width: auto;
    background: #BBCEFF;
    border-radius: 14px;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-family: 'AvenirNext-Regular';
    max-width: 92%;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
}

.mci-questions-btn.selected {
    background:#8BABFF;
    box-shadow: 0px 3px 0px #1D409B;
}

.mci-questions-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'AvenirNext-Bold', sans-serif;
    color: black;
    font-size: 1.4rem;
    text-align: center;
}

@media only screen 
  and (min-width: 600px) 
  and (max-width:1024px){
    .mci-questions-wrapper{
        width: 45%;
    }
  }

  @media only screen and (max-height: 750px) and (max-width: 599px){
    .mci-questions-container{
      font-size: 1.3rem;
    }
    .questions-wrapper{
        gap: 1rem;
    }
}

@media(max-width:599px){
    .mci-questions-btn{
        width: 80vw;
        font-family: var(--primary-sub-heading-font);
    }

    .mci-container{
        height: 100vh;
        align-items: flex-start;
    }

    .mci-questions-wrapper{
        width: 100%;
    }

    .mci-questions-container{
        font-size: 1.3rem;
        color: var(--primary-text-color);
        font-family: var(--primary-heading-font);
    }
}