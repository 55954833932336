.dialogue {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  border-radius: 15px;
  background: #ebebff 0% 0% no-repeat padding-box;
  padding: 40px 33px 50px 34px;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
}

.dialouge-card-box {
  width: 100%;
  padding-left: 15px;
}

.dialogue::before {
  content: '';
  position: absolute;
  right: -80px;
  width: 0;
  border-top: 34px solid transparent;
  border-left: 50px solid #ebebff;
  border-bottom: 30px solid transparent;
  border-right: 30px solid transparent;
}

.dialouge-card-box-heading {
  font-size: 16px;
  color: #00003b;
  font-family: 'AvenirNext-Bold';
  margin-bottom: 23px;
  align-items: center;
  margin-top: 3px;
  margin-right: 7px;
}

.dialouge-card-box-tooltip-wrapper {
  cursor: pointer;
  position: relative;
}

.dialouge-card-box-tooltip-wrapper img {
  height: 17px;
  width: 17px;
  margin-bottom: 4px;
}

.dialouge-card-box-tooltip-wrapper:hover .dialouge-card-box-tooltip {
  visibility: visible;
  opacity: 1;
  left: 0;
  top: 0;
  transform: translateY(calc(-100% - 18px));
}

.dialouge-card-box-tooltip-wrapper.tooltip-right:hover
  .dialouge-card-box-tooltip {
  left: auto;
  right: 0;
}

.dialouge-card-box-tooltip-wrapper.tooltip-right .dialouge-card-box-tooltip {
  left: -110px;
  top: 40px;
}

.dialouge-card-box-tooltip {
  width: 192px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 8px 8px 20px #00005a1a;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  padding: 15px;
  top: 40px;
  margin-left: 4px;
  position: absolute;
}

.dialouge-card-box-tooltip p {
  margin: 0;
  font-size: 10px;
  color: #294962;
  font-family: 'AvenirNext-Regular';
}

.dialouge-card-box-body {
  font-size: 16px;
  color: #294962;
  font-family: 'AvenirNext-Regular';
}

@media screen and (max-width: 1024px) {
  .dialogue {
    padding: 40px 20px 50px 20px;
  }
  .dialouge-card-box {
    width: 100%;
  }
  .dialouge-card-box-tooltip {
    margin-top: 0;
    margin-left: 0;
  }

  .dialouge-card-box {
    width: 100%;
    border-radius: 15px;
    background: #ebebff 0% 0% no-repeat padding-box;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .dialouge-card-box-tooltip-wrapper:hover .dialouge-card-box-tooltip {
    left: -5.5rem;
  }

  .dialogue::before {
    border-top: 34px solid #ebebff;
    border-left: 34px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 30px solid transparent;
    content: '';
    margin-left: -1em;
    position: absolute;
    top: 100%;
    left: 40%;
    width: 0;
    height: 0;
  }
}
