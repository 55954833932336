@media screen and (max-width: 599px) {
  .remark-sheet-modal > .modal-dialog > .modal-content {
    /* height: 55vh; */
    height: 100% !important;
    min-height: 30vh !important;
    width: 100vw !important;
    padding: 1rem;
    left: 0 !important;
    bottom: 0 !important;
    border-radius: 39px 39px 0px 0px !important;
    margin-top: 37rem !important;
  }

  .remark-sheet-modal > .modal-dialog {
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
    height: auto !important;
  }

  .remark-sheet-modal > .modal-dialog > .modal-content > .modal-body {
    padding: 1rem !important;
    border-top: 1px solid lightgray;
    margin-top: 1.5rem;
  }

  .remark-sheet-body-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  /* .remark-sheet-modal-header {
    display: flex;
    width: 100%;
    font-size: 1.313rem;
    font-family: "AvenirNext-Bold";
    color: #000000;
  }

  .remark-description {
    font-size: 1rem;
    font-family: "AvenirNext-Regular";
    color: #000000;
  } */
}

.modal-open-up {
  animation: modal-open-up 0.5s ease;
}

@keyframes modal-open-up {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
}

.remark-sheet-modal > .modal-dialog > .modal-content {
  padding: 1rem;
  left: 0;
  bottom: 0;
  border-radius: 9px;
  border: none;
  margin-top: 100%;
}

.remark-sheet-modal > .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 257px;
}

.remark-sheet-body-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.remark-sheet-modal > .modal-dialog > .modal-content > .modal-body {
  padding: 1rem !important;
  margin-top: 1.5rem;
}

.remark-sheet-modal-header {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1.313rem;
  font-family: "AvenirNext-Bold";
  color: #000000;
}

.remark-description {
  font-size: 1rem;
  font-family: "AvenirNext-Regular";
  color: #000000;
}
