.weak-pointer-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem;
  color: #294962;
}

.weak-section-title {
  margin-top: 4rem;
}

.weak-section-title h2 {
  font-size: 36px;
  font-family: 'AvenirNext-Bold';
}

.weak-section-title p {
  font-size: 16px;
  font-family: 'AvenirNext-Regular';
}

.weak-pointer-info-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 10rem;
}

.weak-pointer-info {
  display: flex;
}

.weak-chart-info {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-bottom: 15%;
}

.weak-point-details {
  display: flex;
  flex-direction: row;
}

.weak-chart-color-box {
  margin-right: 10px;
  height: 25px;
  width: 45px;
  border-radius: 5%;
  background-color: var(--fill);
}

.weak-chart-text-info {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  font-size: 18px;
  font-family: 'AvenirNext-Bold';
  font-weight: bolder;
  color: #294962;
  text-transform: capitalize;
}

.weak-chart-section {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.weak-chart-display {
  justify-content: center;
  padding-top: 50px;
  display: flex;
  width: clamp(10rem, 100%, 20rem);
}

.chart-box {
  width: clamp(10rem, 100%, 20rem);
  height: 90%;
  margin: auto;
}

.box-move {
  padding-right: 50px;
  display: flex;
  flex-direction: row;
}

.weak-section {
  display: flex;
  margin: 50px;
}

.weak-getting-started {
  margin-top: 40px;
  margin-left: -10px;
}

.weak-getting-started-head {
  margin-top: 100px;
  color: #294962;
}

.weak-getting-started-head h2 {
  font-family: 'AvenirNext-Bold';
  font-size: 24px;
  text-transform: capitalize;
}

.weak-getting-started-head p {
  font-family: 'AvenirNext-Regular';
  font-size: 16px;
}

.week-cards-set-base-level {
  margin-top: 30px;
  display: flex;
  padding-bottom: 20px;
}

.week-cards-set-base-level h2 {
  font-family: 'AvenirNext-Bold';
  font-size: 18px;
  color: #294962;
}

.week-cards-set-base-level img {
  padding: 0 0 6px 6px;
}

.week-cards-set-next-level {
  margin-top: 30px;
  display: flex;
}

.week-cards-set-next-level h2 {
  font-family: 'AvenirNext-Bold';
  font-size: 16px;
  color: #294962;
}

.week-cards-set-next-level img {
  padding: 0 0 6px 6px;
}

.card-set {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}

.btn-position {
  margin-left: 50px;
  margin-top: 50px;
}

.level-button {
  display: flex;
  flex-direction: row;
  column-gap: 80px;
}

@media only screen and (min-width: 765px) and (max-width: 1024px) {
  .level-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.mci-levels {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 20px;
}

.mci-level {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 16px;
}

.mci-level-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
}

.mci-level-title-heading {
  font-family: 'AvenirNext-Bold';
  font-size: 24px;
  color: #294962;
}

.mci-level-title-lock {
  width: 26px;
}

.mci-level-tasks {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 50px;
}

.mci-level-task {
  width: 323px;
  height: 133px;
  border-radius: 4px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.mci-level-task-title {
  text-align: center;
  font-family: 'AvenirNext-Bold';
  font-size: 16px;
  color: #fff;
  margin: 0px 12px 0px 12px;
}

.mci-level-task-lock {
  width: 26px;
  align-self: flex-end;
  margin: 5px 5px 0px 0px;
  background: #f1f1ff;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  text-align: center;
  line-height: 32px;
}

.mci-level-task-lock img {
  width: 100%;
}

.mci-level-button {
  width: 195px;
  height: 59px;
  background-color: #a1a1ff;
  border-radius: 4px;
  border: 0px;
}

.mci-level-title-caret-false {
  transform: rotate(180deg);
}

@media screen and (max-width: 765px) {
  .level-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .mci-level-title {
    width: 100%;
    padding-left: 15px;
  }

  .weak-getting-started {
    padding-left: 15px;
  }

  .mci-level-tasks {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
  }

  .weak-section-title h2 {
    font-size: 24px;
  }

  .weak-chart-display {
    justify-content: center;
    display: flex;
    margin: auto;
  }
}
