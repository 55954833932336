.suggestion-card-container {
  display: flex;
  background: #bbceff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  color: black;
  margin: 0 0.5rem;
  min-height: 128px;
  cursor: pointer;
}

.suggestion-card-image-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-basis: 30%;
}

.suggestion-card-info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-basis: 70%;
}

.suggestion-card-title {
  font-family: "AvenirNext-Bold";
}
