.gradient-button-root {
    height: 48px;
    background: transparent linear-gradient(90deg, #AE8625 0%, #F7EF8A 23%, #D2AC47 71%, #EDC967 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gradient-button-label {
    font-family: var(--primary-heading-font);
    font-size: 1rem;
    color: black;
}