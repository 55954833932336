.brain-selector {
  color: #294962;
  margin-bottom: 75px;
  margin-top: 50px;
}

.brain-selector h2 {
  font-size: 36px;
  font-family: 'AvenirNext-Bold';
}

.brain-bottom {
  margin-bottom: 75px;
}

@media screen and (max-width: 599px) {
  .brain-selector {
    width: 100%;
    margin: 0;
  }

  .brain-selector h2 {
    font-size: 24px;
  }
}
