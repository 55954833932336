.about-section {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  color: #294962;
  width: 100%;
}

.about-section h2 {
  font-size: 36px;
  font-family: 'AvenirNext-Bold';
  margin-bottom: 1rem;
}

.about-section p {
  font-size: 16px;
  font-family: 'AvenirNext-Regular';
}

@media screen and (max-width: 600px) {
  .about-section h2 {
    font-size: 24px;
  }

  .about-section p {
    font-size: 14px;
  }
}
