.demographics-heading{
    text-align: center;
    color: black;
    font-size: 1rem;
    font-family: 'AvenirNext-Regular';
}

.pre-screener-input{
        width: 80%;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #A1A1FF;
            border-radius: 14px;
            padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.pre-screener-input:focus{
    outline: none;
    border: 1px solid #A1A1FF;
}

.pre-screener-input::-webkit-inner-spin-button,
.pre-screener-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.demographic-cta{
    width: 35%;
    justify-content: flex-start;
}

.demographic-wrapper{
    width: 30%;
}

.disable-demographic-btn{
        background: grey;
            box-shadow: 3px 3px 0px lightgrey;
}

.custom-select-box {
    /* -moz-appearance: none;
    -webkit-appearance: none; */
  
    background: #212121;
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    border: 1px solid #393939;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  
    border-radius: 8px;
    min-width: 132px;
    min-height: 36px;
  
    font-family: #cecece;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
  
    color: #e1e1e1;
  
    padding: 12px 1rem;
  }
  
  .custom-select-box option {
    background: #2c2c2e;
    border: 1px solid #393939;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.1);
  
    border-radius: 8px;
  }

@media(max-width: 768px){
    .demographic-wrapper {
            width: 100%;
        }
    .demographic-cta {
            width: 93%;
            justify-content: center;
        }
    }
    
@media(max-width:599px){
    .demographics-heading{
        color: var(--primary-text-color);
        font-family: var(--primary-heading-font);
    }
}