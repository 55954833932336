.result-screen {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0 2rem;
  gap: 1rem;
}

.result-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.result-screen-head {
  color: black;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.result-screen-text {
  font-size: 1.2rem;
  font-family: "AvenirNext-Bold";
  margin-bottom: 15px;
  /* white-space: nowrap; */
}

.result-screen-text h6 {
  font-size: 18px;
  font-family: "AvenirNext-Regular";
  margin-bottom: 40px;
}

.result-screen-report-btn button {
  width: 210px;
  height: 59px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 18px;
  color: #000;
  background-color: #a1a1ff;
  font-family: "AvenirNext-Regular";
}

.result-screen-image {
  margin-top: 10%;
  flex-basis: 40%;
}

.result-screen-image img {
  max-width: 100%;
  height: auto;
}

.report-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  cursor: pointer;
}

.report-strength {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.progress-score-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.progress-bar {
  background-color: #3965e0;
}

.react-swipeable-view-container {
  width: 88vw;
}

.result-cards-title-text {
  font-family: "AvenirNext-Bold";
  color: black;
  display: flex;
  padding: 2rem 1rem 0rem 1rem;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
}

.report-progress {
  background-color: #dae4ff;
  height: 13px;
  border-radius: 14px;
  margin: 0;
  width: 100%;
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .result-screen {
    width: 45%;
  }
}

@media screen and (max-width: 600px) {
  .result-screen {
    flex-direction: column;
    padding: 0 1rem;
    width: 100%;
    margin-bottom: 6rem;
  }

  .react-swipeable-view-container {
    width: 88vw;
  }

  .result-cards-title-text{
    font-family: var(--primary-heading-font);
    color: var(--primary-text-color);
    display: flex;
    padding: 2rem 1rem 0rem 1rem;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    /* white-space: nowrap; */
  }

  .report-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
  .report-strength {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .report-card-heading {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .all-report-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: "AvenirNext-Bold";
    background-color: var(--card-background);
    padding: 1rem 1rem;
    border-radius: 12px;
  }

  .progress-score-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .progress-bar {
    background-color: #3965e0;
  }

  .result-screen-head {
    width: 100%;
    flex-basis: 0;
  }

  .result-screen-text {
    font-size: 1.2rem;
    font-family: var(--primary-heading-font);
    color: var(--primary-text-color);
    margin: 25px 0 0 0;
    text-align: center;
  }

  .report-strength{
    font-family: var(--primary-heading-font);
    color: var(--secondary-text-color);
    font-size: 16px;
  }

  .result-screen-text h2 {
    font-size: 24px;
  }

  .result-screen-text h6 {
    font-size: 14px;
  }

  .result-screen-report-btn button {
    font-size: 14px;
  }

  .report-progress {
    height: 5px;
    background-color: #5c5c5c;
  }

  .progress-bar {
    background: linear-gradient(90.57deg, #737DFE -45.69%, #FFCAC9 156.05%);
    border-radius: 8px;
  }
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 46%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.bg-yellow {
  background-color: #ffd955 !important;
}

.bg-green {
  background-color: #35de63 !important;
}

.bg-red {
  background-color: #de575a !important;
}

.bg-orange {
  background-color: #ff8d63 !important;
}
