.healthy-pointer {
  margin-top: 100px;
  margin-bottom: 50px;
  color: #294962;
}
.healthy-pointer h2{
  padding-left: 10px;
  font-size: 36px;
  
  font-family: 'AvenirNext-Bold';
}

.strong-reason {
  display: flex;
  color: #294962;
}

.strong-reason p {
  margin: 20px 0 0 20px;
  font-size: 16px;
  font-weight: 900;
  
}

@media screen and (max-width: 765px) {
  .healthy-pointer h2 {
    font-size: 24px;
  }
}