.start-now {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 2rem;
}

.start-now-head {
  margin-top: 15%;
  color: #294962;
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
}

.start-now-text h2 {
  font-size: 42px;
  font-family: 'AvenirNext-Bold';
  margin-bottom: 15px;
}

.start-now-text h6 {
  font-size: 18px;
  font-family: 'AvenirNext-Regular';
  margin-bottom: 40px;
}

.start-now-report-btn button {
  width: 210px;
  height: 59px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 18px;
  color: #000;
  background-color: #a1a1ff;
  font-family: 'AvenirNext-Regular';
}

.start-now-image {
  margin-top: 10%;
  flex-basis: 40%;
}

.start-now-image img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 600px) {
  .start-now {
    flex-direction: column;
    padding: 0 1rem;
  }

  .start-now-head {
    width: 100%;
    flex-basis: 0;
  }

  .start-now-text h2 {
    font-size: 24px;
  }

  .start-now-text h6 {
    font-size: 14px;
  }

  .start-now-report-btn button {
    font-size: 14px;
  }
}
