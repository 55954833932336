.what-makes-you {
  color: #294962;
  margin: 50px 0;
}

.what-makes-you h2 {
  font-size: 36px;
  font-family: 'AvenirNext-Bold';
  margin-bottom: 15px;
}

.what-makes-you p {
  font-size: 16px;
  font-family: 'AvenirNext-Regular';
}

.what-makes-you-bottom {
  margin-bottom: 75px;
}

.box-select {
  padding-left: 10px;
}

.ellipse {
  background-color: #f0f0ff;
  height: 193px;
  width: 100%;
  border-radius: 50%;
  margin-top: -95px;
}

@media screen and (max-width: 600px) {
  .what-makes-you {
    width: 100%;
  }
  .what-makes-you h2 {
    font-size: 24px;
  }

  .what-makes-you p {
    font-size: 14px;
  }

  .box-select {
    width: 100%;
  }
}

.your-personality {
  display: flex;
  flex-direction: column;
}

.plate {
  width: 550px;
  height: 96px;
  background: #f0f0ff 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100%;
  margin-left: -40px;
  margin-top: -75px;
  z-index: 10;
}

.big-five-index {
  z-index: 30;
}

.big-five {
  display: flex;
  flex-direction: column !important;
}
