.score-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #294962;
}

.mci-score-details {
  display: flex;
}

.mci-score-contribution {
  margin-top: 100px;
  padding-left: 10px;
}

.mci-score-contribution h2 {
  font-size: 36px;
  font-family: 'AvenirNext-Bold';
}

.display-score {
  display: flex;
}

.score-page-chart-box {
  width: clamp(10rem, 100%, 20rem);
  height: 90%;
  margin: auto;
}

.image-under {
  margin-left: -80px;
  margin-top: -25px;
}

.mci-score-graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  flex-basis: 45%;
}

.mci-score-graph img {
  width: 73%;
  margin-top: -3%;
}

.semi-donut-model {
  width: 300px;
  height: 150px;
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  border-radius: 150px 150px 0 0;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
}

.semi-donut-model:before,
.semi-donut-model:after {
  content: '';
  width: 300px;
  height: 150px;
  border: 50px solid var(--fill);
  border-top: none;
  position: absolute;
  transform-origin: 50% 0% 0;
  border-radius: 0 0 300px 300px;
  box-sizing: border-box;
  left: 0;
  top: 100%;
}

.semi-donut-model:before {
  border-color: #eaf9ff;
  transform: rotate(180deg);
}

.semi-donut-model:after {
  z-index: 3;
  animation: 1s fillGraphAnimation ease-in;
  transform: rotate(calc(1deg * (var(--percentage) * 1.8)));
}

.semi-donut-model:hover:after {
  opacity: 0.8;
}

@keyframes fillGraphAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(calc(1deg * (var(--percentage) * 1.8)));
  }
}

.user-score {
  color: #294962;
  font-family: 'AvenirNext-Bold';
  font-size: 30px;
}

.mci-score-combination {
  display: flex;
  flex-direction: column;
  flex-basis: 55%;
  margin-top: 50px;
}

.mci-score-combination h2 {
  font-size: 36px;
  font-family: 'AvenirNext-Bold';
}

.mci-score-combination p {
  font-size: 14px;
  font-family: 'AvenirNext-Regular';
}

.mci-score-section {
  display: flex;
  flex-wrap: wrap;
}

.score-contribution {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.color-box {
  margin-right: 10px;
  height: 21px;
  width: 39px;
  border-radius: 5%;
  background-color: var(--fill);
}

.color-box-text {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  font-size: 18px;
  font-family: 'AvenirNext-Bold';
  font-weight: bolder;
  text-transform: capitalize;
}

.score-page-polar-chart {
  justify-content: center;
  margin-left: 100px;
  padding-top: 50px;
  display: flex;
  width: clamp(10rem, 100%, 20rem);
}

.screenshot {
  margin-top: -60px;
  margin-bottom: 15px;
}

.screenshot button {
  border: none;
  background-color: transparent;
}

@media screen and (max-width: 765px) {
  .mci-score-details {
    flex-direction: column;
  }

  .mci-score-combination {
    width: 100%;
    padding: 0 1rem;
  }

  .mci-score-combination h2 {
    font-size: 24px;
  }

  .mci-score-contribution h2 {
    font-size: 24px;
    padding: 0 1rem;
  }

  .score-page-polar-chart {
    justify-content: center;
    display: flex;
    margin: auto;
  }

  .mci-score-graph img {
    width: 90%;
    margin-top: -3%;
  }
}
