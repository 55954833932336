.mci-page{
    display: flex;
    min-height: 100vh;
    width: 100%;
    background: rgba(187, 206, 255, 0.24);
}

@media(max-width: 599px){
    .mci-page{
     background: none;
    }
}
