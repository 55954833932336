.brain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-active {
  fill: #e0e0ff;
}

.bg-inactive {
  fill: '#e0e0ff';
  fill-opacity: 0;
}

.brain svg {
  max-width: 100%;
}

.circle-effect {
  fill: #a1a1f2;
  opacity: 0.5;
}

#ripple {
  animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    transform: scale(1.4);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

#rp2 {
  animation: ripple2 0.7s linear infinite;
}

@keyframes ripple2 {
  0% {
    transform: scale(3.5);
  }
  100% {
    transform: scale(5.5);
  }
}

#rp3 {
  animation: ripple3 0.7s linear infinite;
}

@keyframes ripple3 {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(3.5);
  }
}

#rp4 {
  animation: ripple4 0.7s linear infinite;
}

@keyframes ripple4 {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1.5);
  }
}
