.onboarding-background {
  width: 100%;
  height: 100%;
  background: rgba(187, 206, 255, 0.24);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
}

.transition-body{

    background: #2A4AA3;
    height: 100vh;
    z-index: 99999999999;

    width: 100vw;

    animation: 0.5s slide-up;
    margin-top: 0%;
    overflow: hidden;

}

@keyframes slide-up {
  from {
    margin-top: 200%;
    height: 100vh;
  }
  to {
    margin-top: 0%;
    height:100vh;
  }
}

.onboarding-container {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  /* background-color: #fafaff; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.title-close-btn {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.title-close-btn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.title-skip-btn {
  display: none;
}

.onboarding-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4rem;
  margin: 0;
  padding-top: 0;
  width: 30%;
  background: #F7FEFF;
  margin-top: 3rem;
  height: 100%;
}

.onboarding-img {
  display: flex;
  justify-content: center;
  flex-basis: 50%;
  max-width: 100%;
  height: auto;
}


.onboarding-text h1 {
  font-size: 36px;
  font-family: 'AvenirNext-Bold';
}
.onboarding-text p {
  font-size: 16px;
  font-family: 'AvenirNext-Regular';
  width: 85%;
}

.onboarding-test-button {
  width: 188px;
  height: 48px;
  background: #a1a1ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.0625rem 0.375rem #00000029;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
  color: #000;
  font-family: 'AvenirNext-Regular';
  border: 0;
}

.onboarding-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: black;
  grid-row-gap: 10px;
  row-gap: 10px;
  font-size: 16px;
  /* margin-top: -3rem; */
  text-align: center;
  padding: 0 2.5rem;
  margin-bottom: 2rem;
}
.onboarding-text-section {
  font-family: 'AvenirNext-Bold';
  color: black;
  font-size: 1.3rem;
}
.onboarding-text-section p {
  width: 100%;
}

.begin-journey-cta{
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 0px #16a9b1;
  border-radius: 4px;
  color: white;
  /* width: inherit; */
  /* padding: 1rem 8rem; */
  font-family: 'AvenirNext-Bold';
  cursor: pointer;
  white-space: nowrap;
  min-height: 2.7rem;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: min-content;
  padding: 0 12px;
}

.test-button {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  padding-bottom: 1.5rem;
  width: 100%;
}

.onboarding-img img {
  min-width: 100%;
  height: auto;
}


@media only screen 
  and (min-width: 600px) 
  and (max-width:1024px){
    .onboarding-info {

      width: 45%;

    }
  }

@media(max-width: 599px){
  .onboarding-modal >  .modal-content{
    width: 100vw;
    height: 100vh;
    top: 2.9rem;
    left: 0;
  }
}

/* @media(max-height: 750px) and (max-width: 768px){ */
  @media only screen and (max-height: 750px) and (max-width: 599px){
  .onboarding-info{
    gap: 2rem;
    /* margin-bottom: 4rem; */
  }
  .onboarding-text-section{
    font-size: 1.3rem;
  }
  .mci-questions-wrapper{
    gap: 1rem !important;
  }
  .mci-questions-wrapper{
    font-size: 1.2rem !important;
  }
  .mci-questions-btn{
    font-size: 0.9rem !important;
  }
  .result-screen-text, .result-cards-title-text{
    font-size: 1.1rem !important;
  }
  .report-strength{
    font-size: 0.9rem !important;
  }
}

@media only screen and (max-height: 500px) and (max-width: 320px){
  .onboarding-background{
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .onboarding-img{
    margin-top: 9rem;
    padding-bottom: 3rem;
  }
}


@media screen and (max-width: 599px) {
  .onboarding-background {
    width: 100%;
    height: 100%;
    background: var(--body-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
  }

  .onboarding-container {
    width: 100%;
    height: 100%;
    border-radius: 0;
    align-items: center;
    justify-content: flex-start;
    background: var(--body-dark);
    position: relative;
  }

  .title-close-btn {
    display: none;
  }

  .modal-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title-skip-btn {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    color: #000000;
    padding: 1rem 1.7rem 0 0;
    text-decoration: underline;
    background-color: #f0f2fd;
  }

  .title-skip-btn button {
    background-color: transparent;
    font-size: 18px !important;
    color: #000;
    border: none;
    cursor: pointer;
  }
  .onboarding-wrapper{
    margin-top: -11rem;
    height: fit-content;
  }

  .onboarding-info {
    width: 100%;
    background-color: var(--body-dark);
  }

  .onboarding-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: black;
    grid-row-gap: 10px;
    row-gap: 10px;
    font-size: 16px;
    font-family: var(--primary-heading-font);
    /* margin-top: -3rem; */
    text-align: center;
    padding: 0 2.5rem;
  }

  .onboarding-text-section {
    font-family: var(--primary-heading-font);
    color: var(--primary-text-color);
    font-size: 1.3rem;
  }

  .onboarding-text-section p {
    font-family: var(--primary-sub-heading-font);
    width: 100%;
    color: var(--primary-text-color);
  }

  .modal-header{
    border-bottom: none;
    margin-bottom: -2rem;
  }

  .hide-header {
    display: none;
  }
}
