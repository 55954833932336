.result-card-container{
    display: flex;
    background: #BBCEFF;
    border-radius: 14px;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    color: black;
    margin: 0 0.5rem;
    min-height: 12rem
}

.result-card-title{
    font-family: 'AvenirNext-Bold';
}

@media (max-width: 599px) {
    .result-card-container{
        background-color: var(--card-background);
    }

    .result-card-title {
        color: var(--primary-text-color);
        font-family: var(--primary-heading-font);
        display: flex;
        align-items: center;
    }

    .result-card-message {
        color: var(--secondary-text-color);
        font-size: 0.9rem !important;
        font-family: var(--primary-sub-heading-font);
    }
}