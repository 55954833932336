.mental-strength {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #294962;
  padding: 0 1rem;
}

.mental-strength h2 {
  font-size: 36px;
  font-family: 'AvenirNext-Bold';
  margin-bottom: 1rem;
}

.mental-strength p {
  font-size: 16px;
  font-family: 'AvenirNext-Regular';
}

.report-icon {
  margin-bottom: 40px;
}

.report-icon img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 599px) {
  .mental-strength h2 {
    font-size: 24px;
  }

  .mental-strength p {
    font-size: 14px;
  }

  .report-icon img {
    max-width: 100%;
    height: auto;
  }
}
